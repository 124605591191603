import React from 'react';
import logo from '../assets/images/Jummbo_Multiplied_10_Orange.png';


const Footer = () => {  
  const currentYear = new Date().getFullYear(); // Get the current year  
  return (  
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>  
      <br></br>
      <br></br>
      <img src={logo} alt="Jummbo_Multiplied_10_Orange" style={{ width: '100%', maxWidth: '400px' }} />  
      <br></br>
      <div className="footer-ack">support@jummbo.ai</div>
      <div className="footer-ack">© Sudo Online Pty Ltd {currentYear}</div>  
      <br></br>
    </div>  
  );  
};  

export default Footer;
