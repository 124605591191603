import React from 'react';
import { Container, Row, Col, Card, Nav } from 'react-bootstrap';
import { NavLink, Link, Navigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import AdminHeader from '../components/AdminHeader';
import MobileMenu from '../components/MobileMenu';


const DashboardPage = () => {

  return (
    <React.Fragment>
      <div className="container">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="main-content">
          <Row>
            <AdminHeader />
          </Row>
          <div class="col-md-3 d-block d-md-none">
            <MobileMenu />
          </div>
          <Row>
            <Col className="adminBackground">
              <h1>Dashboard</h1>
              <Row>
                <Col md={6} lg={6} xl={4}>
                  <NavLink to="/singlesite" style={{ textDecoration: 'none' }}>
                    <div className="card-dashboard">

                      <h4>Prospect a Site</h4>
                      Have a business you want to prospect?
                      <br></br><br></br>
                      <span className="card-description">
                        Simply enter the URL, and Jummbo will generate a prospecting email, call script, and organization summary in under a minute!
                      </span>


                    </div>
                  </NavLink>
                </Col>
                <Col md={6} lg={6} xl={4}>
                  <NavLink to="/listupload" style={{ textDecoration: 'none' }}>
                    <div className="card-dashboard">
                      <h4>Upload a List</h4>
                      <span className="card-description">
                        Already have a list of URLs to prospect?
                        <br></br><br></br>
                        Let Jummbo handle the hard work. For each URL, we'll research the site, craft an outreach email, and generate a call script for you.
                        <br></br>
                      </span>
                    </div>
                  </NavLink>
                </Col>
                <Col md={6} lg={6} xl={4}>
                  <NavLink to="/jobs" style={{ textDecoration: 'none' }}>
                    <div className="card-dashboard">
                      <h4>Search & Build a List</h4>
                      <span className="card-description">
                        Looking for more organizations to sell to?
                        <br></br><br></br>
                        Just specify the industry and location, and Jummbo will find potential prospects for you. We'll take care of the rest.
                        <br></br>
                      </span>
                    </div>
                  </NavLink>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardPage;